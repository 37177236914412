<template>
  <div class="add-user">
    <div class="add-user__title">
      {{ !editUser ? $t('add_edit_user.new_user') : $t('add_edit_user.edit_user_id') + editUser.id }}
    </div>
    <form class="add-user__form" :class="{'locale-usa':locale==='USA'}">
      <el-select v-model="form.role" placeholder="Select" :disabled="currentRole !== 'admin'" :popper-class="locale==='USA'?'locale-usa': ''">
        <el-option
            v-for="(item, key) in role"
            :key="key"
            :label="item"
            :value="key">
        </el-option>
      </el-select>
      <div class="form-input" :class="[{'form-input--full' : form.email},{'form-input--error' : errors.email || (serverErrors && serverErrors.email)}]">
        <input v-model="form.email" type="text" required :disabled="currentRole !== 'admin' && currentRole !== 'manager'">
        <span class="form-input__label"> {{ $t('email') }} </span>
        <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
        <div v-if="serverErrors && serverErrors.email" class="form-input__error">
          <p v-for="(err, index) in serverErrors.email" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <div class="form-input" :class="[{'form-input--full' : form.name},{'form-input--error' : serverErrors && serverErrors.name }]">
        <input v-model="form.name" type="text">
        <span class="form-input__label"> {{ $t('name') }} </span>
        <div v-if="serverErrors && serverErrors.name" class="form-input__error">
          <p v-for="(err, index) in serverErrors.name" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <div class="form-input" :class="[{'form-input--full' : form.tracker_manager_id},{'form-input--error' : serverErrors && serverErrors.tracker_manager_id }]">
        <input v-model="form.tracker_manager_id" type="text">
        <span class="form-input__label"> {{ $t('manager_id') }} </span>
        <div v-if="serverErrors && serverErrors.tracker_manager_id" class="form-input__error">
          <p v-for="(err, index) in serverErrors.tracker_manager_id" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <div 
        class="form-input" 
        :class="
        [
          {'form-input--full' : form.phone},
          {'form-input--error' : errors.phone || (authErrors && authErrors.phone)}
        ]"
      >
        <!-- <input v-if="locale==='RU'" v-model="form.phone" type="tel" v-mask="'+7(###)###-##-##'" required> -->
        <input v-if="locale==='RU'" v-model="form.phone" type="tel" required>
        <input v-if="locale==='USA'" v-model="form.phone" type="tel" v-mask="'+############'" required>
        <span class="form-input__label">{{ $t('phone') }}</span>
        <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
        <div v-if="authErrors && authErrors.phone" class="form-input__error">
          <p v-for="(err, index) in authErrors.phone" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <!-- Password -->
      <div
          class="form-input"
          :class="
        [
          {'form-input--full' : form.password},
          {'form-input--error' : errors.password || (serverErrors && serverErrors.password)}
        ]"
      >
        <input
            v-model="form.password"
            :type="isHidePassword ? 'password' : 'text'"
            :disabled="currentRole !== 'admin'"
            @focus="checkingPassword = true"
            @blur="checkingPassword = false"
        >
        <span class="form-input__label">
          {{ $t('password') }}
        </span>
        <div class="form-input__icon" @click="isHidePassword=!isHidePassword">
          <img v-if="isHidePassword" src="@/assets/icons/icon-eye-closed.svg">
          <img v-else src="@/assets/icons/icon-eye.svg">
        </div>
        <div v-if="errors.password" class="form-input__error" v-text="errors.password" />
        <div v-if="serverErrors && serverErrors.password" class="form-input__error">
          <p v-for="(err, index) in serverErrors.password" :key="index">
            {{ err }}
          </p>
        </div>
        <div v-if="checkingPassword" class="form-input__info">
          <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : form.password && form.password.length > 5}"
          >
            {{ $t('add_edit_user.minimum_symbols') }}
          </div>
          <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : isContainNumber}"
          >
            {{ $t('add_edit_user.minimum_numbers') }}
          </div>
          <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : isContainLetter}"
          >
            {{ $t('add_edit_user.minimum_letters') }}
          </div>
          <div
              class="form-input__info-item"
              :class="{'form-input__info-item--success' : isContainBigLetter}"
          >
            {{ $t('add_edit_user.minimum_capital_letters') }}
          </div>
        </div>
      </div>
      <!-- confirm password -->
      <div
          class="form-input"
          :class="
        [
          {'form-input--full' : form.password_confirmation},
          {'form-input--error' : errors.password_confirmation || (serverErrors && serverErrors.password_confirmation)}
        ]"
      >
        <input v-model="form.password_confirmation" :type="isHidePassword ? 'password' : 'text'" :disabled="currentRole !== 'admin'">
        <span class="form-input__label">
          {{ $t('add_edit_user.repeat_password') }}
        </span>
        <div v-if="errors.password_confirmation" class="form-input__error" v-text="errors.password_confirmation" />
        <div v-if="serverErrors && serverErrors.password_confirmation" class="form-input__error">
          <p v-for="(err, index) in serverErrors.password_confirmation" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
      <!-- Partner block -->
      <div
          v-if="currentRole ==='admin' || currentRole ==='finance' || currentRole ==='manager'"
          class="form-input"
          :class="
        [
          {'form-input--full' : form.partner},
        ]"
      >
        <input v-model="form.partner" type="text" :disabled="currentRole !== 'admin' && currentRole !=='finance' && currentRole !=='manager'">
        <span class="form-input__label">
          {{ $t('add_edit_user.partner') }}
        </span>
      </div>
      <!-- End of the partner block -->
      <!-- Utm block -->
      <div
          v-if="currentRole ==='admin' || currentRole ==='finance' || currentRole ==='manager'"
          class="form-input"
          :class="
        [
          {'form-input--full' : form.utms},
        ]"
      >
        <input v-model="form.utms" type="text" :disabled="currentRole !== 'admin' && currentRole !=='finance' && currentRole !=='manager'">
        <span class="form-input__label">
          {{ $t('add_edit_user.utm') }}
        </span>
      </div>
      <!-- End of the utm block -->
      <div>
        <div class="registration-page__types">
          <label class="user-type">
            <input type="radio" :value="0" v-model="form.type">
            <span> {{ $t('add_edit_user.individual') }}</span>
          </label>
          <label class="user-type">
            <input type="radio" :value="1" v-model="form.type">
            <span>{{ $t('add_edit_user.legal') }}</span>
          </label>
        </div>
        <div v-if="errors.type" class="form-input__error" v-text="errors.type" />
      </div>

      <div class="form-checkbox">
        <div class="form-input">
          <label v-if="form.role === 'user'" class="input__item-checkbox">
            <input
                type="checkbox"
                v-model="form.is_holding"
            >
            <span class="input__item-checkbox__check">
            <img src="@/assets/icons/icon-done.svg">
          </span>
            <span class="input__item-checkbox__info">
            <span class="input__item-checkbox__title">
              {{ $t('add_edit_user.holding') }}
            </span>
          </span>
          </label>
        </div>

        <div class="form-input form-input--deactivate">
          <label class="input__item-checkbox">
            <input
                type="checkbox"
                v-model="form.is_active"
            >
            <span class="input__item-checkbox__check">
          <img src="@/assets/icons/icon-done.svg">
        </span>
            <span class="input__item-checkbox__info">
          <span class="input__item-checkbox__title">
            {{ $t('add_edit_user.active') }}
          </span>
        </span>
          </label>
        </div>

        <div class="form-input form-input--deactivate">
          <label class="input__item-checkbox">
            <input
                type="checkbox"
                v-model="form.is_verified"
            >
            <span class="input__item-checkbox__check">
            <img src="@/assets/icons/icon-done.svg">
            </span>
                <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('add_edit_user.emailVarify') }}
              </span>
            </span>
          </label>
        </div>

        <div class="form-input form-input--deactivate">
          <label class="input__item-checkbox">
            <input
                type="checkbox"
                v-model="form.is_verified_phone"
            >
            <span class="input__item-checkbox__check">
            <img src="@/assets/icons/icon-done.svg">
            </span>
                <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('add_edit_user.phone_verified') }}
              </span>
            </span>
          </label>
        </div>
      </div>

      <div class="form-checkbox">
        <div class="form-input">
          <label class="input__item-checkbox">
            <input
                type="checkbox"
                v-model="form.marketing_subscription"
            >
            <span class="input__item-checkbox__check">
            <img src="@/assets/icons/icon-done.svg">
          </span>
            <span class="input__item-checkbox__info">
            <span class="input__item-checkbox__title">
              {{ $t('business_account_data.marketingMailing') }}
            </span>
          </span>
          </label>
        </div>
      </div>
      <div 
        v-if="editUser.agent_id" 
        class="add-user__refferal"
      >
        {{ $t('add_edit_user.agent_referral') }} "{{ editUser.agent_id }}"
      </div>

      <ButtonElement
        v-loading="isOpeningReferral"
        v-if="editUser.agent && businessAccounts.length"
        :text="$t('profileCard.open_referral')"
        view="empty"
        @click-button="openReferral"
      />
      <button-element @click-button="send" :text="!editUser ? $t('add_edit_user.create') : $t('button_props.save')"/>
    </form>
    <div 
      v-loading="isLoadingBusinessAccount" 
      class="add-user__business-accounts-container"
    >
      <h3 class="add-user__business-accounts-title">Бизнес аккаунты</h3>
      <table class="add-user__table">
        <thead>
          <tr>
            <th>Название / ФИО</th>
            <th style="min-width: 100px">Авторизация</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="businessAccount in businessAccounts"
            :key="businessAccount.id"
          >
            <td>
              <template v-if="businessAccount.is_legal">
                {{ businessAccount.legal_name }}
              </template>
              <template v-else>
                {{ businessAccount.first_name ?businessAccount.first_name : '' }}
                {{ businessAccount.last_name ? businessAccount.last_name : '' }}
              </template>
            </td>
            <td style="min-width: 100px">
              <button type="button" class="add-user__login-business-account-btn" @click="loginBusinessAccount(businessAccount)">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.69231 10.7118C9.69231 8.8669 11.2077 7.37133 13.0769 7.37133H18C18.5098 7.37133 18.9231 7.77921 18.9231 8.28236C18.9231 8.78551 18.5098 9.19339 18 9.19339H13.0769C12.2273 9.19339 11.5385 9.87319 11.5385 10.7118V11.9265C11.5385 12.4296 11.1252 12.8375 10.6154 12.8375C10.1056 12.8375 9.69231 12.4296 9.69231 11.9265V10.7118ZM10.6154 23.1625C11.1252 23.1625 11.5385 23.5704 11.5385 24.0735V25.2882C11.5385 26.1268 12.2273 26.8066 13.0769 26.8066H18C18.5098 26.8066 18.9231 27.2145 18.9231 27.7176C18.9231 28.2208 18.5098 28.6287 18 28.6287H13.0769C11.2077 28.6287 9.69231 27.1331 9.69231 25.2882V24.0735C9.69231 23.5704 10.1056 23.1625 10.6154 23.1625Z" fill="#395FE4"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9231 9.34341C18.9231 8.28589 19.9911 7.55226 20.9959 7.91955L27.1497 10.169C27.7532 10.3896 28.1538 10.9577 28.1538 11.5929V24.4071C28.1538 25.0423 27.7532 25.6104 27.1497 25.831L20.9959 28.0804C19.9911 28.4477 18.9231 27.7141 18.9231 26.6566V9.34341ZM21.6371 6.21092C19.4265 5.40288 17.0769 7.01686 17.0769 9.34341V26.6566C17.0769 28.9831 19.4265 30.5971 21.6371 29.7891L27.7909 27.5396C29.1187 27.0543 30 25.8045 30 24.4071V11.5929C30 10.1954 29.1187 8.9457 27.7909 8.46036L21.6371 6.21092Z" fill="#395FE4"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1934 14.9264C11.5539 14.5706 12.1384 14.5706 12.4989 14.9264L14.9604 17.3558C15.3209 17.7116 15.3209 18.2884 14.9604 18.6442L12.4989 21.0736C12.1384 21.4294 11.5539 21.4294 11.1934 21.0736C10.833 20.7178 10.833 20.141 11.1934 19.7852L12.0792 18.911H6.92308C6.41328 18.911 6 18.5032 6 18C6 17.4969 6.41328 17.089 6.92308 17.089H12.0792L11.1934 16.2148C10.833 15.859 10.833 15.2822 11.1934 14.9264Z" fill="#395FE4"/>
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import formValidate from "@/mixin/formValidate";
import ButtonElement from "../elements/ButtonElement";

export default {
  name: 'AddEditUser',
  components: {ButtonElement},
  mixins: [formValidate],
  data() {
    return {
      isOpeningReferral: false,
      locale: process.env.VUE_APP_LOCALE,
      form: {
        partner: '',
        email: '',
        phone: '',
        role: '',
        password: '',
        password_confirmation: '',
        is_holding: false,
        is_active: false,
        is_verified: false,
        marketing_subscription: true,
        type: null,
        privacy_policy_accepted: true,
        user_agreement_accepted: true,
        phone_verify: false,
        is_verified_phone: false,
        name: '',
        tracker_manager_id: '',
        utms:''
      },
      errors: {
        email: '',
        phone: '',
        role: 'user',
        password: '',
        password_confirmation: '',
        type: null,
      },
      role: {
        finance: this.$t('add_edit_user.role.0'),
        admin: this.$t('add_edit_user.role.1'),
        manager: this.$t('add_edit_user.role.2'),
        user: this.$t('add_edit_user.role.3'),
      },
      isLoadingBusinessAccount: true,
      businessAccounts: [],
      checkingPassword: false,
      isContainNumber: false,
      isContainLetter: false,
      isContainBigLetter: false
    }
  },
  computed: {
    currentPassword() {
      return this.form.password
    },
    editUser() {
      return this.$store.state.Users.editUser
    },
    serverErrors() {
      return this.$store.state.Users.errors
    },
    currentRole() {
      return this.$store.state.Auth.role
    },
    partner() {
      return this.form.partner
    }
  },
  watch: {
    currentPassword(val) {
      const regNumber = /^(?=.*\d).+$/
      this.isContainNumber = regNumber.test(String(val))
      const regLetter = /^(?=.*[a-z]).+$/
      this.isContainLetter = regLetter.test(String(val))
      const regBigLetter = /^(?=.*[A-Z]).+$/
      this.isContainBigLetter = regBigLetter.test(String(val))
    },
    form: {
      deep: true,
      handler () {
        this.$store.commit('Users/setData', { label: 'errors', data: null })
      }
    },
    partner: {
      deep:true,
      handler(value) {
        if(value ===null) this.form.partner = ''
      }
    }
  },
  async mounted() {
    const token = localStorage.getItem('token')
    try {
      const response = await this.$store.dispatch('Users/getUsersListByUserId', { params: {
        user_id: this.editUser.id
      }, token })
      
      this.businessAccounts = response.data.data
    } catch(e) {
      console.error('Failed to fetch business accounts', e)
    } finally {
      this.isLoadingBusinessAccount = false
    }

    this.form.partner = this.editUser ? this.editUser.partner : ''
    this.form.email = this.editUser ? this.editUser.email : ''
    this.form.phone = this.editUser ? this.editUser.phone : ''
    this.form.role = this.editUser ? this.editUser.role : 'user'
    this.form.type = this.editUser ? this.editUser.type : null
    this.form.is_holding = this.editUser ? this.editUser.is_holding : false
    this.form.is_active = this.editUser ? this.editUser.is_active : true
    this.form.is_verified = this.editUser ? this.editUser.email_verified_at !== null : false
    this.form.marketing_subscription = this.editUser ? this.editUser.marketing_subscription : false 
    this.form.is_verified_phone = this.editUser ? (this.editUser.phone_verified_at !== null && this.editUser.phone_verified_at !== '') : false;
    this.form.utms = this.editUser ? this.editUser.utms : ''
    this.form.name = this.editUser ? this.editUser.name : ''
    this.form.tracker_manager_id = this.editUser ? this.editUser.tracker_manager_id : ''
  },
  beforeDestroy() {
    this.form.email = ''
    this.form.role = ''
    this.form.password = ''
    this.form.password_confirmation = ''
    this.form.is_holding = false
    this.form.is_active = true
    this.form.is_verified = false
    this.form.marketing_subscription = false
    this.form.type = null
    this.form.partner = ''
    this.form.name = ''
    this.form.tracker_manager_id = ''
  },
  methods: {
    openReferral() {
      this.isOpeningReferral = true
      const [ businessAccount ] = this.businessAccounts

      const data = {
        id: businessAccount.id,
        token: localStorage.getItem('token')
      }
      
      this.$store.dispatch('Auth/authAsUser', data)
        .then(() => {
          localStorage.setItem('isOpenReferral', true);

          this.$router.push({ name: 'ReferralStatistics' }).then(() => {
            window.location.reload();
          })
        })
    },
    loginBusinessAccount(businessAccount) {
      const data = {
        id: businessAccount.id,
        token: localStorage.getItem('token')
      }
      this.isLoadingBusinessAccount = true

      this.$store.dispatch('Auth/authAsUser', data)
        .then(() => {
          this.$router.push({ path: '/statistics' })
          this.isLoadingBusinessAccount = false
          this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
        })
    },
    send() {
      if (this.currentRole !=='admin') {
        const data = {
          id: this.editUser.id,
          user: {
            is_holding: this.form.is_holding,
            is_active: this.form.is_active,
            is_verified: this.form.is_verified,
            privacy_policy_accepted: this.form.privacy_policy_accepted,
            user_agreement_accepted: this.form.user_agreement_accepted,
            marketing_subscription: this.form.marketing_subscription,
            partner: this.form.partner,
            phone: this.phoneFormat(this.form.phone),
            email: this.form.email,
            password: this.form.password,
            password_confirmation: this.form.password_confirmation,
            role: this.form.role,
            is_verified_phone: this.form.is_verified_phone,
            name: this.form.name,
            tracker_manager_id: this.form.tracker_manager_id,
            utms: this.form.utms
          },
          token: localStorage.getItem('token')
        }
        const formattedData = this.removeEmptyKeys(data)
        this.$store.dispatch('Users/editUser', formattedData)
          .then(() => {
            this.successRequest()
          })
          .catch(err => console.log(err))
      } else {
        this.validateForm()
        if (this.isError) {
          return false
        }

        const dataUser = {
          email: this.form.email,
          phone: this.phoneFormat(this.form.phone),
          role: this.form.role,
          is_holding: this.form.is_holding,
          is_active: this.form.is_active,
          is_verified: this.form.is_verified,
          type: this.form.type,
          partner: this.form.partner,
          privacy_policy_accepted: this.form.privacy_policy_accepted,
          user_agreement_accepted: this.form.user_agreement_accepted,
          marketing_subscription: this.form.marketing_subscription,
          is_verified_phone: this.form.is_verified_phone,
          name: this.form.name,
          tracker_manager_id: this.form.tracker_manager_id,
          utms: this.form.utms
        }

        if (this.form.password) dataUser.password = this.form.password
        if (this.form.password_confirmation) dataUser.password_confirmation = this.form.password_confirmation

        let type = this.editUser ? 'Users/editUser' : 'Users/createUser'
        const formattedUser = this.removeEmptyKeys(dataUser)
        const data = this.editUser ? { id: this.editUser.id, user: formattedUser } : { user: dataUser }
        data.token = localStorage.getItem('token')

        this.$store.dispatch(type, data)
          .then(() => {
            this.successRequest()
          })
          .catch(err => console.log(err))
      }
    },
    successRequest() {
      const params = {
        // role: 'user',
        per_page: 10,
        email: this.$route.query.email
      }
      const token = localStorage.getItem('token')
      this.$store.dispatch('Users/getAllUsersList', { params, token })
      this.$notify({
        message: this.$t('changes_saved'),
        type: 'success'
      });
      this.isRegister = true
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
      setTimeout(() => {
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
      }, 200)
    },
    validateForm () {
      if (this.form.type === null) {
        this.errors.type = this.$t('add_edit_user.job_type')
      }
      if (!this.form.email) {
        this.errors.email = this.$t('forms_notifies.error_mail')
      } else {
        this.validateEmail()
      }
      if (!this.form.phone) {
        this.errors.phone = 'Укажите телефон'
      } else {
        // const regPhone = /^(\+7\(?\d{3}\)?\d{3}-?\d{2}-?\d{2})$/
        // const regPhone = this.locale==='RU'? (/^(\+7\(?\d{3}\)?\d{3}-?\d{2}-?\d{2})$/) : (/^(\+1)?[ -]?\(?([0-9]{3})\)?[ -]?([0-9]{3})[ -]?([0-9]{4})$/)
        const regPhone = this.locale==='RU'? (/^(\+7\(?\d{3}\)?\d{3}-?\d{2}-?\d{2})$/) : (/^(\+)?([0-9]+)$/)

        if (!regPhone.test(String(this.form.phone))) {
          this.errors.phone = this.$t('add_edit_user.correct_number')
        }
      }
      if (!this.editUser && !this.form.password) {
        this.errors.password = this.$t('add_edit_user.input_password')
      }
      if (this.form.password && !this.form.password_confirmation) {
        this.errors.password_confirmation = this.$t('add_edit_user.password_repeat')
      } else if (this.form.password_confirmation !== this.form.password) {
        this.errors.password_confirmation = this.$t('add_edit_user.passwords_dont_match')
      }
    },
    phoneFormat(val) {
      const regex = /[()\-\s]/g;
      if (val) {
        const result = val.replace(regex, '');
        return result;
      }
    },
    removeEmptyKeys(obj) {
      let result = {};
      for (let key in obj) {
        if (obj[key] !== "" && obj[key] !== null) {
          if (typeof obj[key] === "object") {
            result[key] = this.removeEmptyKeys(obj[key]);
          } else {
            result[key] = obj[key];
          }
        }
      }
      return result;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";
.add-user {
  background: #fff;
  color: $basicBlack;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 8rem 8.5rem;

  &__business-accounts-container {
    margin-top: 40px;
  }

  &__business-accounts-title {
    color: $basicBlack;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
  }

  &__login-business-account-btn {
    background: none;
    border: none;
    appearance: none;
  }

  &__table {
    width: 100%;
    border-spacing: 0;

    & > tbody  > tr > td {
      color: $basicBlack;
      font-size: 16px;
      padding: 12px 0;

      &:last-child {
        text-align: center;
        vertical-align: top;
      }
    }
    & > thead  > tr > th {
      text-align: left;
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      color: $gray3;
      border-bottom: 1px solid $gray3;
      padding: 10px 0;

      &:last-child {
        text-align: center;
      }
    }
  }

  &__refferal {
    color: $gray;
    margin-top: 2rem;
  }

  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 3rem;
  }


  @include below(993px) {
    padding: 40px;

    &__title {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }

  @include below(576px) {
    padding: 40px 16px;
    justify-content: center;

    &__title {
      font-size: 18px;
      line-height: 1.4;
      text-align: center;
      margin-bottom: 16px;
    }

    .el-select {
      margin-bottom: 16px;
    }

    .form-checkbox {
      gap: 10px;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
    }

    .input__item-checkbox__title {
      font-size: 14px;
      line-height: 1.4;
    }

    .input__item-checkbox__check {
      margin-right: 4px;
    }

    .button {
      font-size: 14px;
      line-height: 1.4;
      height: auto;
      padding: 8px 12px;
      margin-top: 16px;
    }
  }
}

.button {
  margin-top: 1.5rem;
  width: 100%;
  height: 5rem;
}

.form {

  &-checkbox {
    display: flex;
    // align-items: center;
    // justify-content: space-between;
    margin: 2.5rem 0 0;
    flex-direction: column;
    gap: 1rem;
  }
}

.user-type {
  >input[type="radio"] { //for an input element with type="radio"
          position: absolute;
          z-index: -1;
          opacity: 0;
        }
        //for the label element associated with .settings-choice-item
        >span {       
          display: inline-flex;
          align-items: center;
          user-select: none;
          //creating a before pseudo-element in the label with the following styles
          &::before {
            content: '';
            display: inline-block;
            width: 1em;
            height: 1em;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid #adb5bd;
            border-radius: 50%;
            margin-right: 0.5em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
          }
        }
        //styling when hovering over the radio
        >input[type="radio"]:not(:disabled):not(:checked)+span:hover::before { 
          border-color: var(--primaryLight);
        }
        //styles for active radio button (when clicked)
        >input[type="radio"]:not(:disabled):active+span::before { 
          background-color: var(--primary);
          border-color: var(--primary);
          padding: 5px;
        }
        //styles for a radio button that is in the checked state
        >input[type="radio"]:checked+span::before {
          border-color: var(--primary);
          background-color: var(--primary);
        }
        //styles for a radio button that is in the disabled state
        >input[type="radio"]:disabled+span::before {
          background-color: #e9ecef;
        }
}

</style>
